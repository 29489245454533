body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**   botstrap 5 styles  ***/

/*** Modal ****/
.cursor-pointer {
  cursor: pointer !important;
}

/*********** FONT-SIZE *****************/
.fs-25 {
  font-size: 25px !important;
}
.fs-x{
  font-size: 15px !important;

} 
.fs-18 {
  font-size: 10px;
}
.fs-15{
  font-size: 15px;

}
/*** Modal ****/
.modal-100w {
  max-width: none !important;
  width: 99% !important;
}
.modal-90w {
  max-width: none !important;
  width: 90% !important;
}
.modal-80w {
  max-width: none !important;
  width: 80% !important;
}
.modal-70w {
  max-width: none !important;
  width: 70% !important;
}
.modal-60w {
  max-width: none !important;
  width: 60% !important;
}
.modal-50w {
  max-width: none !important;
  width: 50% !important;
}
.modal-40w {
  max-width: none !important;
  width: 40% !important;
}
.custom-modal-title {
  width: 100%;
}
.titleWithButton {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.modal-2 {
  z-index: 99999 !important;
}
.modal-3 {
  z-index: 999999 !important;
}
.modal-4 {
  z-index: 9999999 !important;
}
.modal-backdrop-2 {
  z-index: 9999 !important;
}
.modal-backdrop-3 {
  z-index: 99999 !important;
}
.modal-backdrop-4 {
  z-index: 999999 !important;
}

/***** fin Modal **********/
.Toastify__toast-container {
  z-index: 99999999 !important;
}

/* buttons */
.modal-50w {
  max-width: none !important;
  width: 50% !important;
}
.btn-primary-app,
.btn-primary-app:hover,
.btn-primary-app:focus {
  background: rgb(224, 240, 239) !important;
  color: rgb(0, 150, 136) !important;
  border-color: rgb(0, 150, 136) !important;
}
.btn-danger-app,
.btn-danger-app:hover,
.btn-danger-app:focus {
  background: rgb(224, 240, 239) !important;
  color: rgb(184, 10, 27) !important;
  border-color: rgb(184, 10, 27) !important;
}
.btn-info-app,
.btn-info-app:hover,
.btn-info-app:focus {
  background: rgb(224, 240, 239) !important;
  color: rgb(45, 64, 203) !important;
  border-color: rgb(45, 64, 203) !important;
}
.btn-warning-app,
.btn-warning-app:hover,
.btn-warning-app:focus {
  background: rgb(224, 240, 239) !important;
  color: rgb(203, 166, 45) !important;
  border-color: rgb(203, 166, 45) !important;
}

/****** background classes  ********/
.bg-aliceblue {
  background: aliceblue;
}
.bg-beige {
  background: beige;
}
.bg-bisque {
  background: bisque;
}
.bg-primary-app {
  background: rgb(224, 240, 239) !important;
}
.bg-secondary-app {
  background: rgb(224, 240, 239) !important;
}
.bg-green-app {
  background: rgb(49, 217, 164) !important;
}
.bg-soft_green {
  background: rgb(220, 251, 241) !important;
}

/****** color classes  ********/
.c-primary {
  color: #009688 !important;
}

.c-aliceblue {
  background: aliceblue;
}
.c-beige {
  background: beige;
}
.c-bisque {
  background: bisque;
}
.c-red {
  background: red;
}
.c-green {
  background: green;
}

.user-list-container .card {
  margin: 0.5rem; /* Ajustez la valeur selon vos besoins */
}
.lien-bleu-souligne {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.color-red {
  color: red !important;
}
.equal-height {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the container takes full height */
}

.equal-height label,
.equal-height input {
  flex: 1; /* Distribute available space equally */
}
.marginitems {
  margin-left: 2px;
}


.input_style {
  border: none;
  width: 100%;
}
.wd-100{
  width: 5px !important;
}
.wd-300{
  width: 300px !important;
}
.wd-250 {
  width: 200px !important;
}
th{
  background-color: #f8f3f3;
}

.mL-3{
  margin-left: 100px !important;

}
@media (min-width: 1560px) {
.ml-3 {
    margin-left: 20px;
  }
}
@media (max-width: 1560px) {
  .marginitems {
    margin-left: 22px;
  }
  .margin-col {
    margin-right: 20px;
  }
  .centerText {
    text-align: center !important;
  }
  .mL-3{
    margin-left: 0px !important;
  
  }
  .wd-250{

    width: 150px !important;
  }
  .fs-x{
    font-size: 10px !important;
  
  } 
}


/**
* custom scroll bar
*/
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100px !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100px !important;
  background: #e0f0ef;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 100px !important;
  background: rgb(199,230,228) !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.modal-footer-padding{
  margin: 1px!important ;
}


/******input****/
.wrapper {
  position:relative;
}

.icon-input {
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px;
  font-size:40px;
  position: absolute;
  box-sizing:border-box;
  top: 5%;
  left:80%;
  transform: translateY(-50%);
}
.icon-input-col6 {
  height: 1.5rem;
  width: 1rem;
  padding: 4px;
  font-size:20px;
  position: absolute;
  box-sizing:border-box;
  top:40%;
  left:85%;
  transform: translateY(-50%);
}
.icon-input {
  height: 1.5rem;
  width: 1rem;
  padding: 4px;
  font-size:20px;
  position: absolute;
  box-sizing:border-box;

}
.input {
  height: 50px;
  box-sizing:border-box;
  padding-left: 1.5rem;
}
.section {
  border: 2px solid #ccc; /* Border style */
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Padding between sections for visual separation */
  margin: 5px; /* Margin for additional space */
}
.custom-swal-width {
  width: 500px; /* Change this value as needed */
}
.full-width {
  width: 100% !important;
  max-width:100% !important
}