@import url("https://fonts.cdnfonts.com/css/seven-segment");

.wrapper #compactSidebar {
  width: 455px;
  position: fixed;
  top: 101px;
  left: 0;
  z-index: 1031;
  transition: 0.3s ease all;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}


.sidebar-closed #compactSidebar {
  left: -455px;
}

.my-swal {
  z-index: 200000;
}

.wrapper #compactSidebar .menu-categories {
  position: relative;
  padding: 0;
  height: calc(100vh - 85px);
  flex-wrap: wrap;
  display: inline-block;
  margin-bottom: 0;
}

.wrapper #compactSidebar .menu-categories.ps .ps__rail-y:hover,
.wrapper #compactSidebar .menu-categories.ps .ps__rail-y.ps--clicking {
  background-color: transparent;
}

.wrapper #compactSidebar .menu-categories > li.menu:first-child {
  float: none;
}

.wrapper #compactSidebar .menu-categories > li.menu {
  position: relative;
  list-style: none;
  width: 30%;
  flex: 0 0 50%;
  padding: 12px;
  float: left;
}

.wrapper #compactSidebar .menu-categories a.menu-toggle {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #515365;
  transition: color 0.3s;
  transition: background 0.3s;
  background: rgb(0 150 136 / 11%);
  border-radius: 10px;
  margin-bottom: 10px;
}

.wrapper #compactSidebar .menu-categories a.menu-toggle:hover {
  border: 1px solid #009688;
}

.wrapper #compactSidebar .menu-categories a.menu-toggle > div.base-menu {
  text-align: center;
}

.wrapper #compactSidebar .menu-categories a.menu-toggle .base-icons {
  text-align: center;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  padding-top: 2px;
}

.wrapper #compactSidebar .menu-categories a.menu-toggle:hover .base-icons {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.wrapper #compactSidebar .menu-categories a.menu-toggle .base-icons svg {
  position: relative;
  display: inline-block;
  color: #009688;
  vertical-align: middle;
  width: 28px;
  height: 28px;
  fill: rgb(0 150 136 / 14%);
  stroke-width: 1px;
}

.wrapper #compactSidebar .menu-categories a.menu-toggle .base-menu span {
  font-size: 12px;
  margin-top: 8px;
  display: inline-block;
  color: #009688;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.wrapper
  #compactSidebar
  .menu-categories
  > li.menu:hover
  svg.feather-chevron-left {
  display: block;
}

/*Data active True*/
.wrapper
  #compactSidebar
  .menu-categories
  > li.menu.active
  svg.feather-chevron-left {
  display: block;
}

.wrapper:not(.hide-mainMenu)
  #compactSidebar
  .menu-categories
  a.menu-toggle[data-active="true"] {
  background-color: #009688;
  box-shadow: 0 10px 20px -10px #009688;
  border: 1px solid #009688;
}

.wrapper:not(.hide-mainMenu)
  #compactSidebar
  .menu-categories
  a.menu-toggle[data-active="true"]
  .base-icons {
  text-align: center;
}

.wrapper:not(.hide-mainMenu)
  #compactSidebar
  .menu-categories
  a.menu-toggle[data-active="true"]
  .base-icons
  svg {
  color: #fff;
  fill: rgb(255 255 255 / 14%);
}

.wrapper:not(.hide-mainMenu)
  #compactSidebar
  .menu-categories
  a.menu-toggle[data-active="true"]
  .base-menu
  span {
  font-weight: 600;
  color: #e0e6ed;
  font-size: 11px;
}

.wrapper #compact_submenuSidebar {
  position: fixed;
  height: 100%;
  background: #e0f0ef;
  width: 200px;
  padding: 50px 0;
  transition: 0.3s ease all;
  background-color: #e0f0ef;
  /* left: 0; */
  z-index: 10;
}

.wrapper #compact_submenuSidebar.show {
  left: 0;
}

.wrapper #compact_submenuSidebar .submenu.show {
  display: block;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list {
  padding: 0;
  margin: 0;
  margin-bottom: 25px;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list:not(.menu-block-submenu) {
  padding: 7px 20px;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list.menu-block-submenu {
  display: flex;
  flex-wrap: wrap;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li.menu-block {
  display: inline-block;
  position: relative;
  list-style: none;
  width: 50%;
  flex: 0 0 50%;
  padding: 12px;
  float: left;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.menu-block
  a:hover {
  border: 1px solid #009688;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li.menu-block a {
  padding: 17px 14px !important;
  text-align: center;
  background: rgb(35 126 112 / 12%);
  border-radius: 10px;
  box-shadow: 0 10px 20px -10px rgba(191, 201, 212, 0.7098039215686275);
  margin-bottom: 10px;
  font-size: 12px;
  color: #009688;
  font-weight: 600;
  letter-spacing: 0.8px;
  border: 1px solid rgb(201 226 224);
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.active.menu-block
  a {
  background-color: #009688;
  box-shadow: 0 10px 20px -10px #009688;
  border: 1px solid #009688;
  color: #fff;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.active.menu-block
  a
  svg:not(.feather-chevron-right) {
  color: #e0e6ed;
  stroke-width: 1.5px;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.menu-block
  a
  svg:not(.feather-chevron-right) {
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  width: 25px;
  height: 25px;
  stroke-width: 1px;
  fill: rgb(0 150 136 / 14%);
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li a {
  padding: 6px 5px;
  display: block;
  color: #506690;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s;
  border-radius: 5px;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li:first-child a {
  padding-top: 0;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list:not(.menu-block-submenu)
  li
  a {
  position: relative;
  display: inline-block;
  font-weight: 500;
  color: #1b2e4b;
  background: linear-gradient(
    to right,
    midnightblue,
    midnightblue 50%,
    #fff 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  text-decoration: none;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list:not(.menu-block-submenu)
  li.active
  > a {
  background-position: 0 100%;
  color: #2196f3;
  -webkit-text-fill-color: #2196f3;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list:not(.menu-block-submenu)
  li.active
  a
  .icon
  svg {
  fill: #2196f3 !important;
  color: #2196f3;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.active
  a
  .icon
  svg.feather-git-commit:not(.feather-chevron-right):not(.feather-folder)
  line {
  color: #2196f3;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a:hover
  .icon
  svg:not(.feather-chevron-right) {
  fill: #3e8e9e4a;
  color: #3e8e9e;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li a .cat-icon {
  display: inline;
  padding: 5px;
  background: #e0e6ed;
  margin-right: 15px;
  border-radius: 6px;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a
  svg:not(.feather-chevron-right) {
  color: #3e8e9e;
  vertical-align: bottom;
  width: 21px;
  height: 21px;
  fill: rgba(6, 8, 24, 0.0784313725490196);
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li a .icon {
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 4px;
  transition: 0.1s;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a
  .icon
  svg:not(.feather-chevron-right) {
  margin-right: 0;
  width: 4px;
  height: 4px;
  align-self: center;
  fill: #3e8e9e;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a
  .icon
  svg.feather-git-commit:not(.feather-chevron-right):not(.feather-folder) {
  transform: rotate(45deg);
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a
  .icon
  svg:not(.feather-chevron-right):not(.feather-chevron-right) {
  margin-right: 0;
  width: 17px;
  height: 17px;
  align-self: center;
  fill: transparent;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a
  .icon
  svg.feather-git-commit:not(.feather-chevron-right):not(.feather-folder)
  line {
  color: rgb(0 150 136 / 41%);
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a
  .icon
  svg.feather-folder {
  margin-bottom: 3px;
  stroke-width: 1.6;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li a:hover {
  background-position: 0 100%;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a:hover
  .icon
  svg:not(.feather-chevron-right) {
  fill: #3e8e9e4a;
  color: #3e8e9e;
  stroke-width: 1.8px;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li
  a
  svg.feather-chevron-right {
  width: 15px;
  height: 15px;
  align-self: center;
  transition: 0.5s;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  a[aria-expanded="true"] {
  transition: 0.3s;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  a[aria-expanded="true"]
  svg.feather-chevron-right {
  transform: rotate(90deg);
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li.sub-submenu a {
  display: flex;
  justify-content: space-between;
}

.wrapper #compact_submenuSidebar .submenu ul.submenu-list li.sub-submenu ul {
  padding-left: 0;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  ul
  li:first-child
  a {
  padding-top: 20px;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  ul
  li
  a {
  position: relative;
  padding: 6px 55px;
  background: transparent;
  border-radius: 0;
  font-size: 13px;
  font-weight: 600;
  box-shadow: none;
  color: #506690;
  -webkit-text-fill-color: #506690;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  ul
  li
  a:hover {
  color: #151516;
  -webkit-text-fill-color: #151516;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  ul
  li.active
  a {
  color: #009688;
  -webkit-text-fill-color: #009688;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  ul
  li.active
  a:before {
  background-color: #009688;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  ul
  li:first-child
  a:before {
  top: 28px;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  ul
  li
  a:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 3px;
  background-color: #888ea8;
  top: 14px;
  left: 37px;
  border-radius: 50%;
}

.wrapper
  #compact_submenuSidebar
  .submenu
  ul.submenu-list
  li.sub-submenu
  ul
  li
  a:hover:before {
  background-color: #304aca;
}

.caisse-data-content {
  margin: 0 auto;
  margin-top: 10px;
  min-height: calc(91vh - 135px);
}

.round-container {
  width: 100px;
  height: 100px;
  border-radius: 20%;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  box-shadow: 0 10px 20px -10px rgba(191, 201, 212, 0.7098039215686275);
  justify-content: end;
  display: flex;
}

.round-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* box-shadow: 2px -1px 26px -5px rgba(0,0,0,0.53);
-webkit-box-shadow: 2px -1px 26px -5px rgba(0,0,0,0.53);
-moz-box-shadow: 2px -1px 26px -5px rgba(0,0,0,0.53); */
}

.color-box {
  display: grid;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.product-item {
  cursor: pointer;
}

/* .product-item :hover {
  box-shadow: 2px -1px 26px -5px rgba(0, 0, 0, 0.69);
  -webkit-box-shadow: 2px -1px 26px -5px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 2px -1px 26px -5px rgba(0, 0, 0, 0.69);
} */

.delete-button {
  right: 0;
  justify-content: center;
  background: #e7515a;
  border-radius: 6px;
  height: 27px;
  width: 27px;
  margin-right: 9px;
  border: none;
}

.minus-button {
  display: inline-flex;
  justify-content: center;
  background: black;
  color: #e0f0ef;
  border-radius: 6px;
  height: 27px;
  width: 27px;
  margin-right: 9px;
  border: none;
}

.times-button {
  display: inline-flex;
  justify-content: center;
  border-radius: 6px;
  height: 27px;
  width: 27px;
  margin-right: 9px;
  margin-top: 3px;
  background: transparent;
  color: white;
  border: 1px solid;
}

#payment-block ul.menu-payments {
  list-style: none;
  padding: 0;
  display: inline-flex;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  gap: 5px;
}

#payment-block ul li.menu {
  position: relative;
  flex: 0 0 23%;
  width: 100%;
  height: 140px;
  list-style: none;
  display: block;
  float: left;
}

#payment-block ul li.menu a.menu-toggle {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #515365;
  transition: all 0.3s;
  background: rgb(0 150 136 / 11%);
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  width: 100%;
}

#payment-block ul li.menu a.menu-toggle:hover {
  border: 1px solid #009688;
}

#payment-block ul li.menu a.menu-toggle .selected {
  border: 1px solid #009688;
}

#payment-block ul li.menu a.menu-toggle .base-menu {
  text-align: center;
}

#payment-block ul li.menu a.menu-toggle .base-menu .base-icons {
  text-align: center;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  padding-top: 2px;
}

#payment-block ul li.menu a.menu-toggle .base-menu .base-icons svg {
  position: relative;
  display: inline-block;
  color: #009688;
  vertical-align: middle;
  width: 28px;
  height: 28px;
  fill: rgb(0 150 136 / 14%);
  stroke-width: 1px;
}

#payment-block ul li.menu a.menu-toggle .base-menu span {
  font-size: 12px;
  margin-top: 8px;
  display: inline-block;
  color: #009688;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.ticket {
  font-size: 12px;
}

.area {
  width: 100px;
  height: 100px;
  background: #c7e6e4;
  border-radius: 20px;
}

#dropZone {
  text-align: center;
  font: 21pt bold;
  color: #009688;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.left-side-trigger {
  cursor: pointer;
  color: #009688;
  background-color: #d1e5e9;
  border-radius: 5px;
  padding: 3px 7px;
  float: right;
}

/**
 * raccourci-tabs
 */
.raccourci-tabs {
  display: flex;
  align-items: center;
  margin-top: 6px;
  overflow-x: auto;
}

.raccourci-tabs label {
  order: 1;
  display: block;
  padding: 10px 20px;
  margin-right: 0.2rem;
  cursor: pointer;
  font-weight: bold;
  transition: background ease 0.2s;
}

.raccourci-tabs .tab,
.raccourci-content .tab {
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 1rem;
  background: #fff;
}

.raccourci-tabs input[type="radio"] {
  display: none;
}

.raccourci-tabs input[type="radio"]:checked + label {
  border-bottom: 2px solid #009688;
  /* Adjust the thickness and color as needed */
}

.raccourci-tabs input[type="radio"]:checked + label + .tab {
  display: block;
}

@media (max-width: 45em) {
  .raccourci-tabs .tab,
  .raccourci-tabs label {
    order: initial;
  }

  .raccourci-tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
}

/**
 * Generic Styling
*/

.input-gray-border {
  border: 1px solid #ccc;
  width: 100px;
}

.add-raccourci-btn {
  background-color: #c7e6e4;
  border-radius: 10px;
  margin-left: 5px;
}

.container-ticket {
  display: block;
  width: 41%;
  background: #fff;
  padding: 50px 15px;
  align-self: center;
}

.container-ticketArticle {
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 10px 10px;
  align-self: center;
}

.receipt_header {
  padding-bottom: 10px;
  border-bottom: 1px dashed #000;
  text-align: center;
}

.receipt_header h1 {
  font-size: 20px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.receipt_header h1 span {
  display: block;
  font-size: 25px;
}

.receipt_header h2 {
  font-size: 14px;
  color: #000;
}

.receipt_header h2 span {
  display: block;
}

.ticket-items table {
  width: 100%;
}

.ticket-items thead,
.ticket-items tfoot {
  position: relative;
}

.ticket-items thead th:not(:last-child) {
  text-align: left;
}

.ticket-items thead th {
  padding: 0 5px 0 5px;
}

.ticket-items thead th:last-child {
  text-align: right;
}

.ticket-items thead::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
}

.ticket-items tbody td:not(:last-child),
tfoot td:not(:last-child) {
  text-align: left;
}

.ticket-items tbody td:last-child,
tfoot td:last-child {
  text-align: right;
}

.ticket-items tbody tr:first-child td {
  padding-top: 15px;
}

.ticket-items tbody tr:last-child td {
  padding-bottom: 15px;
}

.ticket-items tfoot tr:first-child td {
  padding-top: 15px;
}

.ticket-items tfoot::before {
  content: "";
  width: 100%;
  border-top: 1px dashed #000;
  display: block;
  position: absolute;
}

.ticket-items tfoot tr:first-child td:first-child,
.ticket-items tfoot tr:first-child td:last-child {
  font-weight: bold;
  font-size: 20px;
}

.date_time_con {
  display: flex;
  justify-content: center;
  column-gap: 25px;
}

.ticket-items {
  margin-top: 25px;
}

.collapse-button {
  margin: 0 0 0 0;
  color: #3e8e9e;
  background-color: #d1e5e9;
  height: 34px;
  display: inline-flex;
  width: 34px;
  align-self: center;
  justify-content: center;
  border-radius: 10px;
  padding: 7px;
  box-shadow: 0px 0px 20px -10px #3e8e9e;
  margin-right: 12px;
  border: none;
}

.collapse-button-container {
  z-index: 1;
  position: absolute;
  margin-top: 51px;
}

.wrapper {
  transition: all 0.3s ease;
  /* Add transition property for smooth animation */
}

.wrapper.hidden {
  opacity: 0;
  transform: translateX(-100%);
  /* Move the element to the left when hidden */
}

.quantity-badge {
  position: absolute;
  z-index: 2;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
  background-color: #e7515a;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.color-badge {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}

.product-detail-badge {
  display: flex;
  /* font-size: 16px; */
  line-height: 35px;
  border-radius: 10px;
  /* background-color: #ebebeb; */
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.sidebar-collapse {
  margin: 0 0 0 0;
  color: #3e8e9e;
  background-color: #d1e5e9;
  height: 34px;
  width: 34px;
  align-self: center;
  justify-content: center;
  border-radius: 10px;
  padding: 7px;
  box-shadow: 0px 0px 20px -10px #3e8e9e;
  margin-right: 12px;
  border: none;
}

.payment-rightbar {
  width: 448px;
  position: fixed;
  height: 80%;
  background: #030305;
  right: -50%;
  padding: 20px;
  z-index: 10;
  transition: all 0.8s;
  opacity: 0;
  border-radius: 15px;
  justify-self: center;
  align-self: center;
}

.payment-rightbar.displayed {
  opacity: 1;
  right: 448px;
  border-radius: 15px;
  height: 95vh;
  justify-self: center;
  align-self: center;
}

/* fixed payment bar */

.fixed-payment-rightbar {
  width: 600px;
  position: fixed;
  height: 100%;
  background: #030305;
  padding: 20px;
  z-index: 10;
  justify-self: center;
  align-self: center;
  right: 0;
}

/* cart */

.cart-header {
  position: sticky;
  top: 0;
  /* background: #030305; */
  z-index: 1;
  padding: 12px;
}

.cart-footer {
  position: sticky;
  bottom: 0;
  /* background: #030305; */
  padding: 12px;
}

.quantity-input {
  width: 50px;
  background: #151516;
  border: none;
  color: #bfc9d4;
}

.invoice-inbox {
  background-color: white;
  padding: 50px;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.actions-btn-tooltip.tooltip {
  opacity: 1;
  top: -11px !important;
}

.actions-btn-tooltip .arrow:before {
  border-top-color: #3b3f5c;
}

.actions-btn-tooltip .tooltip-inner {
  background: #3b3f5c;
  color: #fff;
  font-weight: 700;
  border-radius: 30px;
  box-shadow: 0px 5px 15px 1px rgba(113, 106, 202, 0.2);
  padding: 4px 16px;
}

.invoice-container {
  width: 100%;
}

.invoice-inbox {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  border-radius: 6px;
}

.invoice-inbox .inv-number {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  color: #888ea8;
}

.invoice-inbox .invoice-action svg {
  cursor: pointer;
  font-weight: 600;
  color: #888ea8;
  margin-right: 6px;
  vertical-align: middle;
  fill: rgba(0, 23, 55, 0.08);
}

.invoice-inbox .invoice-action svg:not(:last-child) {
  margin-right: 15px;
}

.invoice-inbox .invoice-action svg:hover {
  color: #4361ee;
  fill: rgba(27, 85, 226, 0.239216);
}

/*
===================

     Invoice

===================
*/
/*    Inv head section   */
.invoice .content-section .inv--head-section {
  padding: 36px 35px;
  padding-bottom: 25px;
  border-bottom: 1px solid #ebedf2;
}

.inv--customer-detail-section {
  padding: 36px 35px;
  padding-top: 0;
}

.invoice .content-section .inv--head-section h3.in-heading {
  font-size: 18px;
  font-weight: 600;
  color: #0e1726;
  margin: 0;
  margin-left: 12px;
}

.invoice .content-section .inv--head-section .company-logo {
  width: 36px;
  height: 36px;
}

.invoice .content-section .inv--head-section div.company-info {
  display: flex;
  justify-content: flex-end;
}

.invoice .content-section .inv--head-section div.company-info svg {
  width: 42px;
  height: 42px;
  margin-right: 10px;
  color: #4361ee;
  fill: rgba(27, 85, 226, 0.239216);
}

.invoice .content-section .inv--head-section .inv-brand-name {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 0;
  align-self: center;
}

.invoice .content-section .inv--detail-section .inv-to {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 15px;
}

.invoice .content-section .inv--detail-section .inv-customer-name {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 13px;
  color: #4361ee;
}

.invoice .content-section .inv--detail-section .inv-detail-title {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 15px;
  margin-bottom: 15px;
}

.invoice .content-section .inv--detail-section .inv-details {
  font-weight: 700;
  margin-bottom: 15px;
}

.invoice .content-section .inv--detail-section .inv-street-addr,
.invoice .content-section .inv--detail-section .inv-email-address {
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 13px;
}

.invoice .content-section .inv--detail-section .inv-list-number {
  margin-bottom: 2px;
}

.invoice .content-section .inv--detail-section .inv-list-number .inv-title {
  font-weight: 400;
  font-size: 20px;
}

.invoice .content-section .inv--detail-section .inv-list-number .inv-number {
  font-weight: 400;
  font-size: 18px;
  color: #4361ee;
}

.invoice .content-section .inv--detail-section .inv-created-date,
.invoice .content-section .inv--detail-section .inv-due-date {
  margin-bottom: 2px;
}

.invoice .content-section .inv--detail-section .inv-created-date .inv-title,
.invoice .content-section .inv--detail-section .inv-due-date .inv-title {
  font-weight: 700;
  font-size: 13px;
}

.invoice .content-section .inv--detail-section .inv-created-date .inv-date,
.invoice .content-section .inv--detail-section .inv-due-date .inv-date {
  font-size: 13px;
  font-weight: 600;
}

.invoice .content-section .inv--product-table-section {
  padding: 30px 0;
}

.invoice .content-section .inv--product-table-section table {
  margin-bottom: 0;
}

.invoice .content-section .inv--product-table-section thead tr {
  border: none;
}

.invoice .content-section .inv--product-table-section th {
  padding: 9px 22px;
  font-size: 11px !important;
  border: none;
  border-top: 1px solid #e0e6ed;
  border-bottom: 1px solid #e0e6ed;
  color: #515365 !important;
}

.invoice .content-section .inv--product-table-section th:first-child {
  padding-left: 35px;
}

.invoice .content-section .inv--product-table-section th:last-child {
  padding-right: 35px;
}

.invoice .content-section .inv--product-table-section tr td:first-child {
  padding-left: 35px;
}

.invoice .content-section .inv--product-table-section tr td:last-child {
  padding-right: 35px;
}

.invoice .content-section .inv--product-table-section td {
  color: #515365;
  font-weight: 600;
  border: none;
  padding: 7px 9px;
  vertical-align: top !important;
}

.invoice
  .content-section
  .inv--product-table-section
  tbody
  tr:nth-of-type(even)
  td {
  background-color: #fafafa;
}

.invoice .content-section .inv--payment-info {
  font-size: 13px;
  font-weight: 600;
}

.invoice .content-section .inv--payment-info .inv-title {
  color: #4361ee;
  font-weight: 600;
  margin-bottom: 15px;
  width: 65%;
  margin-left: auto;
}

.invoice .content-section .inv--payment-info p {
  margin-bottom: 0;
  display: flex;
  width: 65%;
  margin-left: auto;
  justify-content: space-between;
}

.invoice .content-section .inv--payment-info span {
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
}

.invoice .content-section .inv--payment-info .inv-subtitle {
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  white-space: normal;
  margin-right: 4px;
}

.invoice .content-section .inv--total-amounts {
  padding: 0 35px;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.invoice .content-section .inv--total-amounts .grand-total-title h4,
.invoice .content-section .inv--total-amounts .grand-total-amount h4 {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  padding: 0;
  color: #0e1726;
  display: inline-block;
  letter-spacing: 1px;
}

/*    Inv detail section    */
/*inv-list-number*/
/*inv-created-date*/
/*inv-due-date*/
/*    Inv product table section    */
/*inv--payment-info*/
/*inv--total-amounts*/
/*inv--note*/
.inv--note {
  padding: 0 25px;
  padding-bottom: 25px;
}

.inv--note p {
  margin-bottom: 0;
  font-weight: 600;
  color: #888ea8;
}

/* @media print {
  body * {
    visibility: hidden;
  }
  #ct {
    visibility: visible;
  }
  #ct * {
    visibility: visible;
  }
  .doc-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
} */

@page {
  size: auto;
  margin: 0mm;
}

/*
===============================
    Invoice Actions Button
===============================
*/
.invoice-actions-btn {
  padding: 25px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #fff;
  border: 1px solid #e0e6ed;
  border-radius: 6px;
  margin-top: 25px;
}

.invoice-actions-btn label {
  font-size: 14px;
  font-weight: 600;
  color: #515365;
}

.invoice-actions-btn .invoice-action-btn a {
  -webkit-transform: none;
  transform: none;
}

.invoice-actions-btn .invoice-action-btn a.btn-send,
.invoice-actions-btn .invoice-action-btn a.btn-print,
.invoice-actions-btn .invoice-action-btn a.btn-download {
  width: 100%;
  margin-bottom: 20px;
}

.invoice-actions-btn .invoice-action-btn a.btn-edit {
  width: 100%;
}

/* Invoice Actions -> action-btn */
@media (max-width: 1199px) {
  .invoice-actions-btn {
    margin-top: 25px;
  }

  .invoice-actions-btn .invoice-action-btn a.btn-send,
  .invoice-actions-btn .invoice-action-btn a.btn-print,
  .invoice-actions-btn .invoice-action-btn a.btn-download {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .invoice-actions-btn .invoice-action-btn a.btn-send,
  .invoice-actions-btn .invoice-action-btn a.btn-print {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .invoice .content-section .inv--payment-info .inv-title {
    margin-top: 25px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 6px;
    width: auto;
  }

  .invoice .content-section .inv--payment-info p {
    margin-left: 0;
    margin-right: auto;
    width: auto;
    justify-content: flex-start;
  }

  .invoice .content-section .inv--payment-info .inv-subtitle {
    min-width: 140px;
  }

  .invoice-actions-btn .invoice-action-btn a.btn-download {
    margin-bottom: 20px;
  }

  .invoice .content-section .inv--payment-info span {
    white-space: normal;
  }
}

/* calculator */

.calculator-input {
  color: #acacac;
  height: 50px;
  box-sizing: border-box;
  padding-left: 1.5rem;
}

.result {
  color: #666;
  height: 50px;
}

.calculator-container {
  border-radius: 10%;
  background-color: #000;
}

.number-button {
  color: #000;
  border-radius: 10%;
  border: solid 1px #fff;
  font-size: 2em;
  outline: none;
  appearance: none;
}

.number-button:active {
  background-color: #009688;
  color: #fff;
}

.number-button:hover,
.number-button:active,
.number-button:focus {
  outline: none;
}

.calculator {
  border-radius: 12px;
  box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.calculator__display {
  background-color: #222222;
  color: #fff;
  font-size: 1.714285714em;
  padding: 0.5em 0.75em;
  text-align: right;
}

.calculator__keys {
  background-color: #c7e6e4;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(4, 1fr);
}

.calculator__keys > * {
  background-color: #fff;
  padding: 0.5em 1.25em;
  position: relative;
  text-align: center;
}

.calculator__keys > *:active::before,
.calculator__keys > .is-depressed::before {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5) inset;
  content: "";
  left: 0;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.key--operator {
  background-color: #eee;
}

.key--equal {
  background-color: #e95f2b;
  grid-column: 4;
  grid-row: 2 / span 2;
  border-radius: 10px;
  border: solid 1px #fff;
}

.menu-payments .menu-toggle.selected {
  background-color: lightblue;
  /* Change to the desired background color */
}

.key--submit {
  background-color: #222;
  color: #fff;
  grid-column: 4;
  grid-row: 4 / span 2;
  border-radius: 10px;
  border: solid 1px #fff;
}

.key--submit:active::before,
.key--submit.is-depressed::before {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5) inset;
  content: "";
  left: 0;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.highlighted-row {
  background-color: #e7cfcb !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* For Firefox */
}

/* NumPad.css */

.numpad-container {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 448px;
  background-color: #151516;
  transition: bottom 0.3s ease-out;
  right: 448px;
  justify-content: center;
  align-items: center;
  display: grid;
  z-index: 1;
}

.numpad {
  padding: 10px;
  /* Numpad styles */
}

.numpad-container.show {
  bottom: 0;
  /* Slide in when the container has the 'show' class */
}

.color_red {
  color: red;
}

.total {
  width: 100%;
  z-index: 1;
  background: #151516;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Seven Segment", sans-serif;
  margin-right: 600px;
}

.total h1 {
  font-size: 100px;
  font-weight: bold;
}

/* barcode only settings */
.big-btn {
  height: 62px;
  width: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
}

.big-btn p {
  font-size: large !important;
}

.light-background,
.light-background .cart-header,
.light-background .cart-footer {
  background-color: #fafafa !important;
  color: #3e3e3e;
}

.light-box {
  background-color: #f1f2f3 !important;
  color: #3e3e3e;
}

.light-box .col-6 .w-profile-content h6,
.light-box .col-6 .w-profile-content img,
.light-box .col-6 .w-profile-links span p,
.light-box .col-6 .w-profile-links button {
  color: #3e3e3e;
  box-shadow: none;
}

/* price tag */
.card-price {
  display: inline-block;

  width: auto;
  height: 38px;

  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;
  /* This makes room for the triangle */
  margin-left: 19px;

  position: relative;

  color: white;
  font-weight: 300;
  font-size: 22px !important;
  line-height: 38px;

  padding: 0 10px 0 10px;
  box-shadow: 0 10px 20px -10px rgba(191, 201, 212, 0.7098039215686275);
}

/* Makes the triangle */
.card-price:before {
  content: "";
  position: absolute;
  display: block;
  left: -19px;
  width: 0;
  height: 0;
  border-top: 19px solid #000;
  border-bottom: 19px solid #000;
  box-shadow: 0 10px 20px -10px rgba(191, 201, 212, 0.7098039215686275);
}

/* Makes the circle */
/* .card-price:after {
  content: "";
  background-color: #3e3e3e;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  left: -9px;
  top: 17px;
  box-shadow: 0 10px 20px -10px rgba(191, 201, 212, 0.7098039215686275);
} */
.separator {
  border-left: 1px solid #000;
  /* Changer la couleur et l'épaisseur de la bordure selon vos préférences */
}

/* SearchableDropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
  width: 200px;
}

.dropdown-input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-list-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-list-item:hover {
  background-color: #f0f0f0;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
  table-layout: none;
  white-space: none;
  border: #000;
}

.pretty-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: Arial, sans-serif;
}

.pretty-list li {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}

.pretty-list li:last-child {
  border-bottom: none;
}

.icon {
  margin-right: 10px;
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
}

.custom-disabled {
  background-color: #e0e0e0;
  /* Gray background */
  color: #a0a0a0;
  /* Gray text */
  border: 1px solid #ccc;
  /* Gray border */
  cursor: not-allowed;
  /* Looks like disabled */
}

.baree {
  color: #000;
  text-decoration: line-through !important;
}

.image-zoom {
  position: relative;
  overflow: hidden;
}

.image-zoom img {
  transition: transform 0.5s ease;
  width: 100px;
  height: 100px;
}

.image-zoom:hover img {
  z-index: 100000px;
  width: 1000px;
  height: 1000px;
  transform: scale(1.5);
}

.bon {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #000;
  width: 50%;
  background-color: "#f9f9f9";
}

.print-content {
  display: grid !important;
  grid-template-columns: repeat(
    3,
    8.7cm
  ) !important; /* 3 colonnes de 8.7 cm chacune */
  grid-template-rows: repeat(
    7,
    5.3cm
  ) !important; /* 7 rangées de 5.3 cm chacune */
  gap: 0 !important; /* Pas de gap entre les éléments */
  width: 21cm; /* Largeur d'une page A4 */
  height: auto; /* Hauteur automatique pour permettre le débordement */
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box; /* Inclure le padding et la bordure dans la taille totale */
}
.ticketcontenu {
  transform: rotate(-90deg);
  width: 150px;
  margin: 20px;
}
.ticketcontenu-p {
  transform: rotate(-90deg);
  width: 200px;
  margin: 20px;
}

.print-content-p {
  display: grid !important;
  grid-template-columns: repeat(
    4,
    6.7cm
  ) !important; /* 3 colonnes de 8.7 cm chacune */
  grid-template-rows: repeat(
    10,
    3.6cm
  ) !important; /* 7 rangées de 5.3 cm chacune */
  gap: 0 !important; /* Pas de gap entre les éléments */
  width: 21cm; /* Largeur d'une page A4 */
  height: auto; /* Hauteur automatique pour permettre le débordement */
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box; /* Inclure le padding et la bordure dans la taille totale */
}

.ticket-p {
  box-sizing: border-box;
  overflow: hidden;
  page-break-inside: avoid; /* Évite les ruptures à l'intérieur des cellules */
  break-inside: avoid; /* Assure que les cellules ne sont pas coupées en milieu de page */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  margin-left: 5px;

  width: 3.4cm;
  height: 6.7cm;
}
@media print {

  @page {
    margin: 0; /* Ajustez si nécessaire pour les marges d'impression */
  }
  body,
  .ticket-container {
    width: 80mm; /* Adjust to your POS printer's paper width */
    font-size: small;
    margin: 0;
    padding: 0;
  }
  .ticket-container {
    text-align: left; /* Ensure content aligns to the left */
    width: 100%; /* Full width of the printer paper */
    height: 10000px;
  }

  .bon {
    font-family: Arial, sans-serif;
    border: none;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    margin: 0;
  }
  .bon p {
    font-size: 20px;
  }
  .print-content {
    display: grid !important;
    grid-template-columns: repeat(
      3,
      8.7cm
    ) !important; /* 3 colonnes de 8.7 cm chacune */
    grid-template-rows: repeat(
      7,
      5.3cm
    ) !important; /* 7 rangées de 5.3 cm chacune */
    gap: 0 !important; /* Pas de gap entre les éléments */
    width: 21cm; /* Largeur d'une page A4 */
    height: auto; /* Hauteur automatique pour permettre le débordement */
    padding: 0 !important;
    box-sizing: border-box; /* Inclure le padding et la bordure dans la taille totale */
  }

  .ticket {
    box-sizing: border-box;
    overflow: hidden;
    page-break-inside: avoid; /* Évite les ruptures à l'intérieur des cellules */
    break-inside: avoid; /* Assure que les cellules ne sont pas coupées en milieu de page */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;

    width: 8.7cm;
    height: 5.3cm; /* Assurez-vous que la hauteur correspond à la taille de la grille */
  }

  .print-content-p {
    display: grid !important;
    grid-template-columns: repeat(
      4,
      6.7cm
    ) !important; /* 3 colonnes de 8.7 cm chacune */
    grid-template-rows: repeat(
      10,
      3.4cm
    ) !important; /* 7 rangées de 5.3 cm chacune */
    gap: 0 !important; /* Pas de gap entre les éléments */
    width: 21cm; /* Largeur d'une page A4 */
    height: auto; /* Hauteur automatique pour permettre le débordement */

    box-sizing: border-box; /* Inclure le padding et la bordure dans la taille totale */
  }

  .ticket-p {
    box-sizing: border-box;
    overflow: hidden;
    page-break-inside: avoid; /* Évite les ruptures à l'intérieur des cellules */
    break-inside: avoid; /* Assure que les cellules ne sont pas coupées en milieu de page */
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    font-size: 12px;
    width: 3.4cm;
    height: 6.7cm; /* Assurez-vous que la hauteur correspond à la taille de la grille */
  }

  /* Optionnel : pour gérer le contenu trop grand dans une cellule */
  .ticket img {
    max-width: 100%;
    height: auto;
  }
  .ticket-p img {
    max-width: 100%;
    height: auto;
  }
}

/*
@media print {
  @page {
    size: A4 ;
    margin: 0;
  }

.print-content {
  display: grid !important;
  grid-template-columns: repeat(3, 8.7cm) !important;
  grid-template-rows: repeat(7, 5.3cm) !important;
  gap: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box;
  overflow: hidden;
}


.ticket {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
  page-break-inside: avoid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.bon {
  font-family: Arial, sans-serif;
  border: none;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
}
.bon p {
  font-size: 20px;

}

  
  .print-content-p {

    display: grid !important;
    grid-template-columns: repeat(10, 4cm) !important; 
    grid-template-rows: repeat(4, 7.1cm) !important;
    gap: 0 !important; 
    width: 100% !important; 
    height: 100% !important;
    padding-left: 0 !important;
    margin:  0 !important;
}

.print-content-p {
  display: grid !important;
  grid-template-columns: repeat(minmax(4cm, 1fr), minmax(4cm, 1fr)) !important; 
  grid-auto-rows: minmax(7.1cm, auto) !important; 
  gap: 0 !important; 
  width: 100% !important; 
  height: 100% !important;
  padding: 0 !important;
  margin:  0 !important;
}

.ticket-p {
      
  box-sizing: border-box !important;
  padding: 5px !important;
  font-size: 10px !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  
  margin-top: 5px;
  
}

}
*/
.custom-dropdown-options {
  position: absolute; /* Positions the dropdown relative to its parent */
  background-color: #fff; /* Background color of the dropdown */
  border: 1px solid #ccc; /* Border to define the dropdown area */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth effect */
  z-index: 1000; /* Ensure it appears above other elements */
  padding: 0;
  margin: 0;
  width: 100%; /* Match the width of the parent container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  max-height: 200px; /* Maximum height of the dropdown */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
}

.custom-dropdown-option {
  padding: 10px 15px; /* Space inside each option */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s ease; /* Smooth background color transition */
}

.custom-dropdown-option:hover {
  background-color: #f0f0f0; /* Background color on hover */
}

.custom-dropdown-option:active {
  background-color: #ddd; /* Background color when clicked */
}

.custom-dropdown-option + .custom-dropdown-option {
  border-top: 1px solid #eee; /* Border between options */
}
.accordion-button::after {
  content: none !important;
}
.cardstyle {
  border: 2px solid black;
  background-color: rgb(204, 204, 204);
}
.cardstyle:hover {
  border: 2px solid black;
  background-color: rgb(164, 162, 162);
}
